const Button = ({
    children,
    icon,
    onClick,
    isLoading,
    buttonType,
    paddingClass = 'py-3.5 px-8',
    wrapperclass = '',
    borderRadiusClass = 'rounded-lg',
    cursorClass = 'cursor-pointer',
    gtmTag = '',
    ...property
}) => {
    return (
        <div className={`${wrapperclass}`} onClick={onClick}>
            {(() => {
                if (
                    property.design == 'standard' &&
                    property.type == 'primary'
                ) {
                    return (
                        <button
                            type={buttonType}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            className={`relative  ${paddingClass}   flex justify-center items-center capitalize ${property.textcolor
                                ? property.textcolor
                                : 'text-basicWhite'
                                }  ${borderRadiusClass} ${property.color
                                    ? property.color
                                    : 'bg-primary-500'
                                } text-${property.size}  ${property.className} ${property.state === 'disabled'
                                    ? 'opacity-50'
                                    : ''
                                }
                            ${property.borderClass ? property.borderClass : ''}
                            ${isLoading ? 'cursor-not-allowed' : cursorClass}`}
                            id={property.id}
                            gtmTag={gtmTag ? gtmTag : property.id}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 rounded-full border-basicWhite left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
                if (property.design == 'standard' && property.type == 'ghost') {
                    return (
                        <button
                            type={buttonType}
                            disabled={
                                property.state === 'disabled' || isLoading
                            }
                            className={`relative ${paddingClass} flex border border-gray-600 ${borderRadiusClass} justify-center items-center capitalize text-${property.size
                                }  ${property.className} ${property.textcolor
                                    ? property.textcolor
                                    : 'text-primary-500'
                            }
                                ${isLoading ? 'cursor-not-allowed' : cursorClass}
                                `}
                            id={property.id}
                            gtmTag={gtmTag ? gtmTag : property.id}
                        >
                            {isLoading && (
                                <div className="absolute inline-block w-4 h-4 border-b-2 rounded-full border-primary-500 left-5 animate-spin"></div>
                            )}
                            {children}
                        </button>
                    );
                }
            })()}
        </div>
    );
};
export default Button;
